import React from "react";
import Header from "../../component/Header";
import "../../assets/style/login.css";
import { blogbefore, proafter, arrow } from "../../constant/index";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { SetResetPassword } from "../../redux/actions/AuthActions";
import { toast } from "react-toastify";
import { useState } from "react";
import queryString from "query-string";

const ChangePassword = () => {
    const location = useLocation();
    const Navigate = useNavigate()
    const dispatch = useDispatch();
    const [ password , setPassword ] = useState("")
    const [ confirmPassword , setConfirmPassword ] = useState("")
    const [loading, setLoading] = useState(false);
    const value = queryString.parse(location.search);
    const token = value.token;
    // console.log("token", token);
  
    const NewPasswordHandler = async (e) => {
      e.preventDefault();
      setLoading(true);
      if (!password || !confirmPassword ) {
        toast.error("Please Fill all fields");
        setLoading(false);
        return;
      }
      if (password.length < 8 ) {
        toast.error("Password must be greater than 7 digits");
        setLoading(false);
        return;
      }
      if (password !== confirmPassword) {
        toast.error("Please Enter Same password and Confirm Password");
        setLoading(false);
        return;
      }
    
      let data = {
        reset_token: token,
        password: password,
        confirm_password: confirmPassword,
      };
    //   console.log(data);
      setTimeout(async () => {
        let send = await dispatch(SetResetPassword(data, Navigate));
        setLoading(false);
        //   Navigate("/Signin");
      }, 600);
    };

    return (
        <>
        <Header/>
            <section className="login">
                <div className="before">
                    <figure>
                        <img src={blogbefore} className="img-fluid" />
                    </figure>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-2"></div>
                        <div className="col-md-8">
                            <div className="auth-box">
                                <div className="heading">
                                    <h2>Change Password</h2>
                                    <h4>
                                        Please Enter A{" "}<span className="bld">Password</span>
                                    </h4>
                                </div>
                                <form action="login.php">
                                    <div className="form-group">
                                        <label>New Password</label>
                                        <input type="password" className="form-control" 
                                        value={password}
                                        onChange={(e)=>setPassword(e.target.value)}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Confirm New Password</label>
                                        <input type="password" className="form-control" 
                                         value={confirmPassword}
                                         onChange={(e)=>setConfirmPassword(e.target.value)}
                                        />
                                    </div>
                                   
                                    <button className="btn"
                                     onClick={(e)=>NewPasswordHandler(e)}
                                     disabled={loading}
                                    >
                                        <span>{loading ? "Loading..." : "Submit"}</span>{" "}
                                        <img src={arrow} className="img-fluid" />
                                    </button>
                                </form>
                            </div>
                        </div>
                        <div className="col-md-2"></div>
                    </div>
                </div>
                <div className="after">
                    <figure>
                        <img src={proafter} className="img-fluid" />
                    </figure>
                </div>
            </section>
        </>
    );
};

export default ChangePassword;
