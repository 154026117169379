import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { no_image_product_details } from "../../constant";

const ProductCard = (props) => {
  const { index, item, colValue, pageLink } = props;
  const ImageUrlRedux = useSelector((state) => state.AuthReducer.urlData);
  // console.log(pageLink);
  return (
    <div
      className={colValue == "4" ? "col-lg-4 mb-4" : "col-lg-3 mb-4"}
      key={index}
    >
      <div className="product-box">
        <Link to={`${pageLink}/${item?.id}`}>
          <div className="pro-img">
            <figure>
              <img
                src={
                  item?.thumbnail &&  item?.thumbnail != null
                    ? `${ImageUrlRedux?.product_thumbnail_url}/${item?.thumbnail}`
                    : no_image_product_details
                }
                alt=""
                className="img-fluid"
              />
            </figure>
          </div>
          <div className="pro-detail">
            <div className="name">
              <h4>{item?.name}</h4>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default ProductCard;
