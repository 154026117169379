import React from "react";
import { useSelector } from "react-redux";
import { cart1, no_image_product_details } from "../../constant";
import { useNavigate } from "react-router-dom";

const FrequentProduct = (props) => {
	const {
		productDetails,
		addFreq,
		AddFrequentHandler,
		FrequentVariations,
		FrequentAddToCartHandler,
		waiting,
		FrequentVariationPrice,
		freqSize,
	} = props;
	const ImageUrlRedux = useSelector((state) => state.AuthReducer.urlData);
	const naviagte = useNavigate();

	// const fildsdsd = (index) => {
	// 	let data = addFreq?.filter((val) => val?.index === index);

	// 	console.log(data, "datadata");
	// };

	// console.log(addFreq, "addFreq");
	return (
		<>
			{productDetails?.frequent_products.length > 0 ? (
				<section className="frequently_bought">
					<div className="container">
						<div className="heading">
							<h2>Frequently Bought Together</h2>
						</div>
						<div className="row">
							{productDetails?.frequent_products?.map((item, index) => {
								return (
									<>
										{index < 4 ? (
											<div className="col-lg-3 col-md-6 mb-3" key={index}>
												<div
													className={`${
														index == 3 ? "product-box" : "product-box pro_box"
													}`}
													id="multi_select"
												>
													{/* <Link to={`/product-detail/${item?.id}`}> */}
													<div
														className={`${
															addFreq
																?.map((item) => {
																	return item?.item?.id;
																})
																.includes?.(item?.id)
																? "selected-freq pro-img"
																: "pro-img"
														}`}
														onClick={(e) => AddFrequentHandler(e, item, index)}
													>
														<figure>
															<img
																src={
																	item?.thumbnail && item?.thumbnail != null
																		? `${ImageUrlRedux?.product_thumbnail_url}/${item?.thumbnail}`
																		: `${no_image_product_details}`
																}
																alt=""
																className="img-fluid"
															/>
														</figure>
													</div>
													<div className="pro-detail">
														<div className="name">
															<h4>
																<a href={`${"/product-detail/"}${item?.id}`}>
																	{item?.name}
																</a>
															</h4>
														</div>
														<div className="price">
															<h4>
																$
																{(addFreq?.length === 1) &
																(addFreq[0]?.item?.id === item?.id)
																	? addFreq[0]?.selectedPrice
																	: (addFreq?.length === 2) &
																	  (addFreq[1]?.item?.id === item?.id)
																	? addFreq[1]?.selectedPrice
																	: (addFreq?.length === 3) &
																	  (addFreq[2]?.item?.id === item?.id)
																	? addFreq[2]?.selectedPrice
																	: (addFreq?.length === 4) &
																	  (addFreq[3]?.item?.id === item?.id)
																	? addFreq[3]?.selectedPrice
																	: item?.unit_price}
																$
															</h4>
															{/* freqSize ? FrequentVariationPrice() : */}
														</div>
													</div>

													{/* </Link> */}
												</div>

												<select
													id="size"
													className="form-control"
													key={index}
													onChange={(e) =>
														FrequentVariations(e.target.value, item)
													}
												>
													<option selected disabled>
														Select Size
													</option>
													{item?.variation?.map((item2, index) => {
														return (
															<option value={item2?.size} key={index}>
																{item2?.size}
															</option>
														);
													})}
												</select>
											</div>
										) : null}
									</>
								);
							})}
							<div className="button-group frequentlybtn">
								<button
									className="btn"
									onClick={(e) => FrequentAddToCartHandler(e)}
									disabled={waiting || addFreq?.length < 1}
								>
									{waiting ? "Please Wait" : "Add to cart"}{" "}
									<img src={cart1} className="img-fluid" />
								</button>
							</div>
						</div>
					</div>
				</section>
			) : null}
		</>
	);
};

export default FrequentProduct;
