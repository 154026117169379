
export const ApiUrl =
"https://kigi-nursery.developer-ourbase-camp.com/api/v1" ||
"https://localhost:4000";


// export const ApiUrl =
// "https://admin.kigibirds.com/api/v1" ||
// "https://localhost:4000";

