import logo from './logo.svg';
import './App.css';
import '../src/assets/style/style.css';
import '../src/assets/style/Custom.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import PublicRoutes from './routes/PublicRoutes';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import 'react-loading-skeleton/dist/skeleton.css'
import { GetCategories, GetSiteSettingsApi, GetWorkStep } from './network/Network';
import { useEffect } from 'react';
import { AllCategoriesData, WebSiteSettings, WorkStepData } from './redux/actions/AuthActions';
import { useDispatch } from 'react-redux';

function App() {
  const dispatch = useDispatch()

    // Get Categories Data
    useEffect(() => {
      GetCategories()
        .then((res) => {
          // console.log(res);
          dispatch(AllCategoriesData(res?.data));
        })
        .catch((err) => {
          // console.log(err);
        });
    }, []);

    // Get Pages Data
    useEffect(() => {
      GetSiteSettingsApi()
        .then((res) => {
          // console.log(res);
          dispatch(WebSiteSettings(res?.data?.data));
        })
        .catch((err) => {
          // console.log(err);
        });
    }, []);

    // Get step work Home
    useEffect(() => {
      GetWorkStep()
        .then((res) => {
          // console.log("step", res);
          dispatch(WorkStepData(res?.data?.data))
        })
        .catch((err) => {
          // console.log(err);
        });
    }, []);

  return (
    <>
  <PublicRoutes />
  <ToastContainer />
  </>
  );
}

export default App;
