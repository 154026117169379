import React, { useEffect, useState } from "react";
import Footer from "../../component/Footer";
import Header from "../../component/Header";
import "../../assets/style/contact.css";
import { ContactUsApi } from "../../network/Network";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const Contact = () => {
	const SiteSettingsRedux = useSelector(
		(state) => state.AuthReducer.siteSettings,
	);
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [phone, setPhone] = useState("");
	const [email, setEmail] = useState("");
	const [message, setMessage] = useState("");
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const ContactUsHandler = (e) => {
		e.preventDefault();
		setLoading(true);
		if (!firstName || !lastName || !phone || !email || !message) {
			toast.error("Please Enter All Fields");
			setLoading(false);
			return;
		}
		if (firstName?.length < 3 || firstName?.length > 15) {
			toast.error("First name must be between 3 to 15 characters");
			setLoading(false);
			return;
		}
		if (lastName?.length < 3 || lastName?.length > 15) {
			toast.error("Last name must be between 3 to 15 characters");
			setLoading(false);
			return;
		}

		if (
			!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
				email,
			)
		) {
			toast.error("Invalid Email");
			setLoading(false);
			return;
		}

		if (phone.length < 11 || phone.length > 15) {
			toast.error("Phone number must be betwween 11 to 15 digits");
			setLoading(false);
			return;
		}
		if (message.length < 20) {
			toast.error("The message must be at least 20 characters");
			setLoading(false);
			return;
		}
		let data = {
			first_name: firstName,
			last_name: lastName,
			mobile_number: phone,
			email: email,
			message: message,
		};
		ContactUsApi(data)
			.then((res) => {
				// console.log("contact", res);
				toast.success(res?.data?.message);
				setLoading(false);
				setFirstName("");
				setLastName("");
				setPhone("");
				setEmail("");
				setMessage("");
			})
			.catch((err) => {
				// console.log(err);
				setLoading(false);
			});
	};
	return (
		<>
			<Header />
			{/* <!-- InnerBanner Sec Start Here --> */}
			<section className="innerBanner">
				<div className="container">
					<div className="inner-box">
						<div className="title">
							<h2>Contact Us</h2>
						</div>
					</div>
				</div>
			</section>
			{/* <!-- InnerBanner Sec End Here --> */}

			{/* <!-- Contact Sec Start Here --> */}
			<section className="contact_sec">
				<div className="container">
					<div className="heading">
						<h2>Let Us Answer All Your Questions </h2>
					</div>
					<div className="row">
						<div className="col-lg-2"></div>
						<div className="col-lg-8">
							<form action="">
								<div className="row">
									<div className="col-md-6">
										<div className="form-group">
											<label>First Name</label>
											<input
												type="text"
												className="form-control"
												value={firstName}
												onChange={(e) => setFirstName(e.target.value)}
											/>
										</div>
									</div>
									<div className="col-md-6">
										<div className="form-group">
											<label>Last Name</label>
											<input
												type="text"
												className="form-control"
												value={lastName}
												onChange={(e) => setLastName(e.target.value)}
											/>
										</div>
									</div>
									<div className="col-md-6">
										<div className="form-group">
											<label>Phone</label>
											<input
												type="number"
												className="form-control"
												value={phone}
												onChange={(e) => setPhone(e.target.value)}
											/>
										</div>
									</div>
									<div className="col-md-6">
										<div className="form-group">
											<label>Email</label>
											<input
												type="email"
												className="form-control"
												value={email}
												onChange={(e) => setEmail(e.target.value)}
											/>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group">
											<label>Message</label>
											<textarea
												className="form-control"
												value={message}
												onChange={(e) => setMessage(e.target.value)}
											></textarea>
										</div>
									</div>
									<div className="col-md-12">
										<button
											className="btn"
											type="submit"
											onClick={(e) => ContactUsHandler(e)}
											disabled={loading}
										>
											{loading ? "Loading.." : "Submit"}
										</button>
									</div>
								</div>
							</form>
							{/* <!-- <div className="button-group">
                            <a href="#">Support Center</a>
                        </div> --> */}
						</div>
						<div className="col-lg-2"></div>
						<div className="col-md-4 mt-3">
							<div className="address">
								<div className="icon">
									<i className="fa fa-envelope" aria-hidden="true"></i>
								</div>
								<div className="txt">
									<h3>Email us</h3>
									<p>
										Email us for general queries, including marketing and
										partnership opportunities.
									</p>
									<p className="colrd">
										{SiteSettingsRedux?.kigi_company_email}
									</p>
								</div>
							</div>
						</div>
						<div className="col-md-4 mt-3">
							<div className="address">
								<div className="icon">
									<i className="fa fa-phone" aria-hidden="true"></i>
								</div>
								<div className="txt">
									<h3>Call Us</h3>
									<p>
										Call us to speak to a member of our team. We are always
										happy to help.
									</p>
									<p className="colrd">
										+{SiteSettingsRedux?.kigi_company_phone}
									</p>
								</div>
							</div>
						</div>
						<div className="col-md-4 mt-3">
							<div className="address">
								<div className="icon">
									<i className="fa fa-map-marker" aria-hidden="true"></i>
								</div>
								<div className="txt">
									<h3>Our Head Office</h3>
									<p>{SiteSettingsRedux?.kigi_address}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* <!-- Contact Sec End Here --> */}
			<Footer />
		</>
	);
};

export default Contact;
