import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { arrow, btnicon } from "../../constant";

const HomeCategorySection = (props) => {
	const { CategoriesReduxData } = props;
	const ImageUrlRedux = useSelector((state) => state.AuthReducer.urlData);
	return (
		<>
			<section className="categories_sec">
				<div className="before">
					<figure>
						<img src="img/cat-before.png" alt="" className="img-fluid" />
					</figure>
				</div>
				<div className="container">
					<div className="discover">
						<div className="heading_wrapper">
							<h2>Categories</h2>
						</div>
						<div className="view-all">
							<Link to="/all-plants" className="lnk">
								Browse More <img src={btnicon} />
							</Link>
						</div>
					</div>
					<div className="row">
						{CategoriesReduxData !== undefined && CategoriesReduxData[0] ? (
							<div className="col-lg-7 spacer-768">
								<div className="categories_box frst_box">
									<div className="content">
										<h2 className="title">
											{CategoriesReduxData !== undefined &&
												CategoriesReduxData[0]?.name}
										</h2>
										<p>
											{CategoriesReduxData !== undefined &&
												CategoriesReduxData[0]?.description}
										</p>
										<div className="button-group">
											<Link
												to={
													CategoriesReduxData !== undefined &&
													CategoriesReduxData[0]?.name == "All Plants"
														? "/all-plants"
														: CategoriesReduxData !== undefined &&
														  CategoriesReduxData[0]?.name ==
																"NUTRIENTS & ADDITIVES"
														? "/nutrients-additives"
														: CategoriesReduxData !== undefined &&
														  CategoriesReduxData[0]?.name == "KIGI BIRDS"
														? "/kigibird"
														: CategoriesReduxData !== undefined &&
														  CategoriesReduxData[0]?.name == "PLANTING MIXES"
														? "/planting-mixes"
														: "/premium-products"
												}
												className="btn"
											>
												SHOP NOW <img src={arrow} />
											</Link>
										</div>
									</div>
									<div className="img_box frstImg">
										<figure>
											<img
												src={`${ImageUrlRedux?.category_image_url}/${
													CategoriesReduxData !== undefined &&
													CategoriesReduxData[0]?.icon
												}`}
												className="img-fluid"
											/>
										</figure>
									</div>
								</div>
							</div>
						) : null}
						{/* <div className="spacer-768"></div> */}
						<div className="col-lg-5">
							{CategoriesReduxData !== undefined && CategoriesReduxData[1] ? (
								<div className="categories_box scnd_box">
									<div className="content">
										<h2 className="title">
											{CategoriesReduxData !== undefined &&
												CategoriesReduxData[1]?.name}
										</h2>
										<p>
											{CategoriesReduxData !== undefined &&
												CategoriesReduxData[1]?.description}
										</p>
										<div className="button-group">
											<Link
												to={
													CategoriesReduxData !== undefined &&
													CategoriesReduxData[1]?.name == "All Plants"
														? "/all-plants"
														: CategoriesReduxData !== undefined &&
														  CategoriesReduxData[1]?.name ==
																"NUTRIENTS & ADDITIVES"
														? "/nutrients-additives"
														: CategoriesReduxData !== undefined &&
														  CategoriesReduxData[1]?.name == "KIGI BIRDS"
														? "/kigibird"
														: CategoriesReduxData !== undefined &&
														  CategoriesReduxData[1]?.name == "PLANTING MIXES"
														? "/planting-mixes"
														: "/premium-products"
												}
												className="btn"
											>
												SHOP NOW <img src={arrow} />
											</Link>
										</div>
									</div>
									<div className="img_box scndImg">
										<figure>
											<img
												src={`${ImageUrlRedux?.category_image_url}/${
													CategoriesReduxData !== undefined &&
													CategoriesReduxData[1]?.icon
												}`}
												className="img-fluid"
											/>
										</figure>
									</div>
								</div>
							) : null}

							<div className="spacer"></div>
							{CategoriesReduxData !== undefined && CategoriesReduxData[2] ? (
								<div className="categories_box thrd_box">
									<div className="content">
										<h2 className="title">
											{CategoriesReduxData !== undefined &&
												CategoriesReduxData[2]?.name}
										</h2>
										<p>
											{CategoriesReduxData !== undefined &&
												CategoriesReduxData[2]?.description}
										</p>
										<div className="button-group">
											<Link
												to={
													CategoriesReduxData !== undefined &&
													CategoriesReduxData[2]?.name == "All Plants"
														? "/all-plants"
														: CategoriesReduxData !== undefined &&
														  CategoriesReduxData[2]?.name ==
																"NUTRIENTS & ADDITIVES"
														? "/nutrients-additives"
														: CategoriesReduxData !== undefined &&
														  CategoriesReduxData[2]?.name == "KIGI BIRDS"
														? "/kigibird"
														: CategoriesReduxData !== undefined &&
														  CategoriesReduxData[2]?.name == "PLANTING MIXES"
														? "/planting-mixes"
														: "/premium-products"
												}
												className="btn"
											>
												SHOP NOW <img src={arrow} />
											</Link>
										</div>
									</div>
									<div className="img_box thrdImg">
										<figure>
											<img
												src={`${ImageUrlRedux?.category_image_url}/${
													CategoriesReduxData !== undefined &&
													CategoriesReduxData[2]?.icon
												}`}
												className="img-fluid"
											/>
										</figure>
									</div>
								</div>
							) : null}
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default HomeCategorySection;
