import React, { useEffect, useState } from "react";
import "../../assets/style/profile.css";
import Footer from "../Footer";
import Header from "../Header";
import { proafter, probefore, approved } from "../../constant/index";
import { Link } from "react-router-dom";
import { MyDonations, UpdateProfile } from "../../network/Network";
import { EditProfileData } from "../../redux/actions/AuthActions";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import SideBar from "../SideBar/SideBar";
import Skeleton from "react-loading-skeleton";
import NoRecord from "../NoRecord/NoRecord";
import moment from "moment";
import ReactPaginate from "react-paginate";
import ViewDonationModal from "../Modal/ViewDonationModal";

const MyDonation = () => {
  const dispatch = useDispatch();
  const Token = useSelector((state) => state.AuthReducer.token);
  const userData = useSelector((state) => state.AuthReducer.users);
  const [myDonationData, setMyDonationData] = useState([]);
  const [spinLoad, setSpinLoad] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [selectedData, setSelectedData] = useState();
  const [isOpenViewDonationModal, setIsOpenViewDonationModal] = useState(false);

  const handlePageClick = async (data) => {
    setCurrentPage(data?.selected + 1);
  };

  useEffect(() => {
    setSpinLoad(true);
    MyDonations(currentPage, Token)
      .then((res) => {
        // console.log("my donation", res);
        setMyDonationData(res?.data?.data?.data);
        const total = res?.data?.data?.total;
        const limit = res?.data?.data?.per_page;
        setPageCount(Math.ceil(total / limit));
        setSpinLoad(false);
      })
      .catch((err) => {
        // console.log(err);
        setSpinLoad(false);
      });
  }, [currentPage]);

  return (
    <>
      <Header />
      {/* <!-- Profile Sec Start Here --> */}
      <section className="profile-sec">
        <div className="before">
          <figure>
            <img src={probefore} className="img-fluid" />
          </figure>
        </div>
        <div className="container">
          <div className="heading">
            <h2>My Profile</h2>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <SideBar />
            </div>
            <div className="col-lg-8">
              <div className="tab-content" id="v-pills-tabContent">
                {/* <!-- Third Tab Content --> */}
                <div
                  className={"tab-pane fade show active"}
                  id="v-pills-Donations"
                  role="tabpanel"
                  aria-labelledby="v-pills-Donations-tab"
                >
                  <div className="tab-box sixth-box">
                    <div className="donations box">
                      <div className="heading">
                        <h3>My Donations</h3>
                      </div>
                      {spinLoad ? (
                        <>
                          {[0, 1, 2, 3, 4, 5, 7, 8, 9]?.map((item, index) => {
                            return (
                              <div className="col-md-12 mt-3" key={index}>
                                <Skeleton count={5} />
                              </div>
                            );
                          })}
                        </>
                      ) : myDonationData?.length > 0 ? (
                        <>
                          <div className="donation-table">
                            <div className="table-reponsive">
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th>Booking Campaign</th>
                                    <th>Donation Date & Time</th>
                                    <th>Payment Status</th>
                                    <th>Total</th>
                                    <th>Details</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {myDonationData?.map((item, index) => {
                                    return (
                                      <>
                                        <tr key={index}>
                                          <td>#{item?.id}</td>
                                          <td>
                                            {moment(item?.created_at).format(
                                              "MMMM Do YYYY, h:mm a"
                                            )}{" "}
                                          </td>
                                          <td>
                                            <div className="status">
                                              <span>
                                                {item?.payment_status?.toUpperCase()}
                                              </span>
                                              <figure>
                                                <img
                                                  src={approved}
                                                  className="img-fluid"
                                                />
                                              </figure>
                                            </div>
                                          </td>
                                          <td>${item?.amount}</td>
                                          <td>
                                            <button
                                              type="button"
                                              className="btn btn-primary"
                                              // data-bs-toggle="modal"
                                              // data-bs-target="#donationDetail"
                                              onClick={(e) => {
                                                setIsOpenViewDonationModal(
                                                  true
                                                );
                                                setSelectedData(item);
                                              }}
                                            >
                                              View Detail
                                            </button>
                                          </td>
                                        </tr>
                                      </>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </>
                      ) : (
                        <NoRecord />
                      )}

                      <div className="paginatio-count">
                        <div className="pagination-container mt-5">
                          <ReactPaginate
                            previousLabel="<"
                            nextLabel=">"
                            breakLabel={"..."}
                            pageCount={pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={3}
                            onPageChange={handlePageClick}
                            containerClassName={
                              "pagination justify-content-center"
                            }
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            activeClassName={"active"}
                          />
                        </div>
                        {myDonationData?.length > 0 ? (
                          <p className="total-pages">
                            {myDonationData?.length} PRODUCTS 1 -{" "}
                            {myDonationData?.length}
                          </p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- Third Tab Content --> */}
              </div>
            </div>
          </div>
        </div>
        <div className="after">
          <figure>
            <img src={proafter} className="img-fluid" />
          </figure>
        </div>
      </section>
      {/* <!-- Profile Sec End Here --> */}

      <Footer />

      <ViewDonationModal
        isOpenViewDonationModal={isOpenViewDonationModal}
        setIsOpenViewDonationModal={setIsOpenViewDonationModal}
        selectedData={selectedData}
      />
    </>
  );
};

export default MyDonation;
