import React, { useEffect, useState } from "react";
import Footer from "../../component/Footer";
import Header from "../../component/Header";
import "../../assets/style/campaign.css";
import PieChart from "react-pie-graph-chart";
import {
  smallafter,
  smallbefore,
  arrow,
  no_image_product_details,
} from "../../constant/index";
import { Link, useNavigate } from "react-router-dom";
import { PostCampaignDetails } from "../../network/Network";
import { ramdomImage } from "../../constant/ConstantFunction";
import { useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import DonarListModal from "../../component/Modal/DonarListModal";

const Campaign = () => {
  const navigate = useNavigate();
  const ImageUrlRedux = useSelector((state) => state.AuthReducer.urlData);
  let currentUrl = window.location.href.split("/");
  const [campaignDetails, setCampaignDetails] = useState([]);
  const [spinLoad, setSpinLoad] = useState(false);
  const [isOpenDonarModal, setIsOpenDonarModal] = useState(false);
  

  useEffect(() => {
    setSpinLoad(true)
    PostCampaignDetails(currentUrl[4])
      .then((res) => {
        // console.log("camp details", res);
        setCampaignDetails(res?.data);
        setSpinLoad(false)
        // dispatch(AllStates(res?.data?.response?.data))
      })
      .catch((err) => {
        // console.log(err);
        setSpinLoad(false)
      });
  }, [currentUrl[4]]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const pieChartData = [
    {
      type: "Goal",
      value: campaignDetails?.total_donation == campaignDetails?.campaign?.goal_price ? 0 : campaignDetails?.campaign?.goal_price ,
      // value: 100,
      color: "#c23127",
    },
    {
      type: "Donation",
      value: 100,
      value: campaignDetails?.total_donation == campaignDetails?.campaign?.goal_price ? 100 : campaignDetails?.total_donation,
      // value: (campaignDetails?.total_donation/campaignDetails?.campaign?.goal_price)*100,
      color: "#62B170",
    },
  ];
  return (
    <>
      <Header />
      {/* <!-- InnerBanner Sec Start Here --> */}
      <section className="innerBanner">
        <div className="container">
          <div className="inner-box">
            <div className="title">
              <h2>Campaign</h2>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- InnerBanner Sec End Here --> */}

      {/* <!-- Campaign Sec Start Here --> */}
      <section className="campaign">
        {
          spinLoad ? (
            <>
            {[0, 1, 2, 3, 4, 5, 7, 8, 9]?.map((item, index) => {
              return (
                <div className="container" key={index}>
                  <Skeleton count={5} />
                </div>
              );
            })}
          </>
          ) : (
            <>
                     <div className="before">
          <figure>
            <img src={smallafter} className="img-fluid" />
          </figure>
        </div>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="img_box">
                <figure>
                  <img
                    src={campaignDetails?.campaign?.image && campaignDetails?.campaign?.image != null ?`${ImageUrlRedux?.campaign_image_url}/${campaignDetails?.campaign?.image}` : no_image_product_details}
                    className="img-fluid"
                  />
                </figure>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="content-wrapper">
                <h2> {campaignDetails?.campaign?.title}</h2>
                <p>{campaignDetails?.campaign?.description}</p>

                <div className="for-chart">
                  <div className="left">
                    <PieChart data={pieChartData} />
                  </div>
                  <div className="right">
                    {/* <button
                      type="button"
                      className="btn btn-primary"
                      data-bs-toggle="modal"
                      data-bs-target="#donors"
                
                    > */}
                      <div className="total">
                        <div className="box">
                          <h4>${campaignDetails?.campaign?.goal_price}</h4>
                          <h5>Goal</h5>
                        </div>
                        <div className="box">
                          <h4>${campaignDetails?.total_donation}</h4>
                          <h5>Donated</h5>
                        </div>
                        <div className="box"
                                onClick={(e)=>setIsOpenDonarModal(true)}
                                style={{cursor: "pointer"}}
                        >
                          <h4>{campaignDetails?.campaign?.donation?.length}</h4>
                          <h5>Donors</h5>
                        </div>
                      </div>
                    {/* </button> */}
                  </div>
                </div>
                <div className="button-group">
                  <button
                    className="btn donate-btn"
                    disabled={campaignDetails?.campaign?.goal_price === campaignDetails?.total_donation ? true : false}
                    onClick={() =>
                      navigate(`/donatenow`, { state: { data: {id: currentUrl[4], page: "camping"} } })
                    }
                  >
                    DONATE NOW
                    <img src={arrow} className="img-fluid" />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="row align-items-center scnd_row mt-5">
            <div className="col-lg-6">
              <div className="content-wrapper">
                <h2> {campaignDetails?.campaign?.middle_section_heading}</h2>
                <p>{campaignDetails?.campaign?.middle_section_description}</p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="img_box">
                <figure>
                  <img
                    src={`${ImageUrlRedux?.campaign_image_url}/${campaignDetails?.campaign?.middle_section_image}`}
                    className="img-fluid"
                  />
                </figure>
              </div>
            </div>
          </div>
        </div>
        <div className="after">
          <figure>
            <img src={smallbefore} className="img-fluid" />
          </figure>
        </div>
            </>
          )
        }
 
      </section>
      {/* <!-- Campaign Sec End Here --> */}

      {/* <!-- Our Donor Sec Start Here --> */}
      <section className="our_donor">
        <div className="container">
          <div className="heading">
            <h2>Our Donors</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident.
            </p>
          </div>
          <div className="row">
            {campaignDetails?.donors?.map((item, index) => (
              <>
              {
                index < 5 ? (
                  <div className="col-lg-3 col-md-6" key={index}>
                  <div className="img-box">
                    <figure className="donar-user-img">
                      {item?.user?.image == null ? (
                        <img
                          src={`${ramdomImage(
                            `${item?.user?.f_name}{""}${item?.user?.l_name}`
                          )}`}
                          className="image-fluid image-width"
                          style={{ borderRadius: "50%", width: "100%" }}
                          alt=""
                        />
                      ) : (
                        <img
                          src={`${ImageUrlRedux?.customer_image_url}/${item?.user?.image}`}
                          alt="all-Images"
                          className="img-fuild"
                        />
                      )}
                    </figure>
                  </div>
                  <p className="donor-list-name">{item?.user?.f_name}{item?.user?.l_name}</p>
                </div>
                ) : (null)
              }
               
              </>
            ))}

          </div>
          <div className="button-group">
            <button
              className="btn donate-btn"
              disabled={campaignDetails?.campaign?.goal_price === campaignDetails?.total_donation ? true : false}
              onClick={() =>
                navigate(`/donatenow`, { state: { data: {id: currentUrl[4], page: "camping"} } })
              }
            >
              DONATE NOW
              <img src={arrow} className="img-fluid" />
            </button>
          </div>
        </div>
      </section>
      {/* <!-- Our Donor Sec End Here --> */}

      {/* <!-- Donors Modal --> */}
    
      {/* <!-- Donors Modal --> */}
      <Footer />
      <DonarListModal
        setIsOpenDonarModal={setIsOpenDonarModal}
        isOpenDonarModal={isOpenDonarModal}
        campaignDetails={campaignDetails}
        />
    </>
  );
};

export default Campaign;
