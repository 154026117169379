import React, { useEffect, useState } from "react";
import Footer from "../../component/Footer";
import Header from "../../component/Header";
import "../../assets/style/cart.css";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  ClearCart,
  DecrementProductQuantity,
  deleteCartItem,
  IncrementProductQuantity,
  UpdateCart,
} from "../../redux/actions/CartActions";
import { toast } from "react-toastify";
import NoRecord from "../../component/NoRecord/NoRecord";
import { no_image_product_details } from "../../constant";

const Cart = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cartDataRedux = useSelector((state) => state.CartReducer.cartData);
  const DeliveryDateRedux = useSelector(
    (state) => state.CartReducer.deliveryDates
  );
  const ImageUrlRedux = useSelector((state) => state.AuthReducer.urlData);
  const isLogin = useSelector((state) => state.AuthReducer.isLogin);
  const [cartData, setCartData] = useState(cartDataRedux);
  const [subTotal, setSubTotal] = useState(0);
  const [deliveryDate, setDeliveryDate] = useState();
  const [totalDonation, setTotalDonation] = useState(0);
  const [frequentData, setFrequentData] = useState([]);

  let newDate = new Date();
  let CurrentMonth = newDate.getMonth() + 1;

  const TotalAmount = () => {
    let i;
    let total = 0;
    let donationTotal = 0;
    for (i = 0; i < cartDataRedux.length; i++) {
      // console.log("cart", cartDataRedux[i]?.price);
      if (cartDataRedux[i]?.campaign_price) {
        donationTotal =
          parseInt(donationTotal) + parseInt(cartDataRedux[i]?.campaign_price);
      }
      if (cartDataRedux[i]?.type && cartDataRedux[i]?.type == "bulk") {
        total = total + cartDataRedux[i]?.price * 1;
      } else {
        total = total + cartDataRedux[i]?.price * cartDataRedux[i]?.quantity;
      }
      if (cartDataRedux[i]?.campaign_price) {
        total = parseInt(total) + parseInt(cartDataRedux[i]?.campaign_price);
      }
      if (cartDataRedux[i]?.addon_item) {
        let j;
        for (j = 0; j < cartDataRedux[i]?.addon_item.length; j++) {
          total =
            parseInt(total) +
            parseInt(
              cartDataRedux[i]?.addon_item[j]?.addon_item
                ? cartDataRedux[i]?.addon_item[j]?.addon_item[0]?.variation[0]
                    ?.price
                : 0
            );
        }
      }
      setSubTotal(total);
      setTotalDonation(donationTotal);
    }
  };

  useEffect(() => {
    TotalAmount();
  }, [cartDataRedux]);

  useEffect(() => {
    let result = [];
    if (cartDataRedux?.length > 0) {
      async function AllFrequent() {
        for (let i = 0; i < cartDataRedux?.length; i++) {
          for (
            let j = 0;
            j < cartDataRedux[i]?.productitem?.frequent_products?.length;
            j++
          ) {
            result.push(cartDataRedux[i]?.productitem?.frequent_products[j]);
          }
        }
        // console.log(result, "frequentDatafrequentData");
        await RemoveDuplicateFrequent(result);
      }
      AllFrequent();
    }
  }, []);

  const RemoveDuplicateFrequent = async (result) => {
    if (result?.length > 0) {
      const map = new Map();
      const result2 = [];
      for (const item of result) {
        if (!map.has(item?.id)) {
          map.set(item?.id, true);
          result2.push(item);
        }
      }
      setFrequentData(result2);
      // console.log("uniqueValuesuniqueValuesuniqueValues", result2);
    }
  };

  const Increment = (id) => {
    dispatch(IncrementProductQuantity(id));
  };
  const Decrement = (id) => {
    dispatch(DecrementProductQuantity(id));
  };

  const RemoveCartItem = (id) => {
    const items = cartData;
    let currentItem = id;
    if (items.length > 0) {
      setCartData(
        items.filter((item, index) => item?.productitem?.id !== currentItem)
      );
      dispatch(deleteCartItem(currentItem));
      //   setPostList(items.filter((item, index) => index !== currentItem));
    }
  };

  const filterVariationQty = (item) => {
    let varient = item?.size;
    if (item?.productitem?.variation.length > 0) {
      let newVarient = item?.productitem?.variation?.filter(
        (val) => val?.size == item?.size
      );
      if (newVarient?.length > 0) {
        return newVarient[0]?.qty ;
      }
      return 1;
    }
    return 1;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const LoginHandler = (e) => {
    e.preventDefault();
    if (!isLogin) {
      toast.error("Please Login");
      navigate("/login");
      return;
    }
    if (cartDataRedux?.length < 1) {
      toast.error("Please Add Products ");
      return;
    }
  };

  const DeliveryDateHandler = (e, id) => {
    const items = cartData;
    if (items?.length > 0) {
      let i;
      for (i = 0; i < cartDataRedux.length; i++) {
        if (cartDataRedux[i]?.productitem?.id === id) {
          items[i] = {
            id: cartDataRedux[i]?.id,
            price: cartDataRedux[i]?.price,
            quantity: cartDataRedux[i]?.quantity,
            size: cartDataRedux[i]?.size,
            campaign_price: cartDataRedux[i]?.campaign_price,
            campaign_id: cartDataRedux[i]?.campaign_id,
            productitem: cartDataRedux[i]?.productitem,
            deliveryDate: e.target.value,

            addon_item: cartDataRedux[i]?.addon_item,
            // addon_id: cartDataRedux[i]?.addon_id,
            type: cartDataRedux[i]?.type,
            product_variant_id: cartDataRedux[i]?.product_variant_id,
            // addon_variant_id: cartDataRedux[i]?.addon_variant_id,
            // addon_variationPrice: cartDataRedux[i]?.addon_variationPrice,
            // addonQuantity: cartDataRedux[i]?.addonQuantity,
          };
          // console.log(items[i]);
        }
      }
      dispatch(UpdateCart(items));
    }
  };

  const SpecificItemHandler = (item) => {
    // console.log(item)
    let i;
    let total = 0;
    for (i = 0; i < item.length; i++) {
      // item[0]?.addon_item[0]?.variation[0]?.price
      total =
        parseInt(total) +
        parseInt(
          item[i]?.addon_item ? item[i]?.addon_item[0]?.variation[0]?.price : 0
        );
    }
    return total;
  };

  return (
    <>
      <Header />
      {/* <!-- InnerBanner Sec Start Here --> */}
      <section className="innerBanner">
        <div className="container">
          <div className="inner-box">
            <div className="title">
              <h2>Shopping Cart</h2>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- InnerBanner Sec End Here --> */}

      {/* <!-- Cart Sec Start Here --> */}
      <section className="cart_sec">
        <div className="container">
          <div className="table-responsive">
            <table className="table mb-0">
              <thead>
                <tr>
                  <th></th>
                  <th>Product Name</th>
                  <th>Detail</th>
                  <th>Addons</th>
                  {/* <th>Delivery date</th> */}
                  <th>Price</th>
                  <th className="quantityTh">Quantity</th>
                  <th>Subtotal</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {cartDataRedux?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td className="td-cart-img">
                        <figure>
                          <img
                            src={
                              item?.productitem?.thumbnail &&  item?.productitem?.thumbnail != null
                                ? `${ImageUrlRedux?.product_thumbnail_url}/${item?.productitem?.thumbnail}`
                                : no_image_product_details
                            }
                            className="img-fluid"
                          />
                        </figure>
                      </td>
                      <td>
                        <h4
                          className="pro-name"
                          onClick={() =>
                            navigate(`/product-detail/${item?.productitem?.id}`)
                          }
                          style={{ cursor: "pointer" }}
                        >
                          {item?.productitem?.name}
                        </h4>
                        {item?.productitem?.category?.name == "All Plants" ? (
                          <select
                            onChange={(e) =>
                              DeliveryDateHandler(e, item?.productitem?.id)
                            }
                          >
                            <option disabled>
                              Select Delivery Date
                              {/* {CurrentMonth == 1 || CurrentMonth == 2 ? "Winter (January - February) Southern & West Coast States + HI" : 
                                CurrentMonth == 3 || CurrentMonth == 4 ? "Early Spring (March - April) All Lower 48 States + HI" :
                                CurrentMonth == 5 || CurrentMonth == 6 ? "Late Spring (May - June) Northern & West Coast States + HI+ AK" :
                                CurrentMonth == 9 || CurrentMonth == 10 || CurrentMonth == 11 ? "Fall (September - November) All Lower 48 States + HI" :
                                "Select Delivery Date"
                              } */}
                            </option>
                            {DeliveryDateRedux?.map((item, index) => {
                              return (
                                <option value={item} key={index}>
                                  {item}
                                </option>
                              );
                            })}
                          </select>
                        ) : null}
                      </td>
                      <td>
                        {/* <h4 className="variation-type">{item?.color}</h4> */}
                        <h4 className="variation-type">
                          {item?.productitem?.category?.name}
                        </h4>

                        {item?.size ? (
                          <h4 className="variation-type">
                            {item?.size} size
                            {filterVariationQty(item) > 0 &&
                            filterVariationQty(item) < 10 ? (
                              <>
                                : only {filterVariationQty(item)}{" "}
                                {filterVariationQty(item) > 1
                                  ? "items"
                                  : "item"}{" "}
                                left
                              </>
                            ) : null}
                          </h4>
                        ) : null}

                        {/* <h4 className="variation-type">
                          {item?.productitem?.type}
                        </h4> */}
                        {item?.type == "bulk" ? (
                          <h4 className="variation-type">{item?.type}</h4>
                        ) : null}
                      </td>

                      <td>
                        {item?.addon_item?.length > 0 ? (
                          <>
                            {item?.addon_item?.map((item) => {
                              return (
                                <h4
                                  className="variation-type"
                                  key={item?.addon_item[0]?.id}
                                >
                                  {item?.addon_item[0]?.name} $
                                  {item?.addon_item[0]?.variation[0]
                                    ? item?.addon_item[0]?.variation[0]?.price
                                    : null}{" "}
                                  {item?.addon_item[0]?.variation[0]
                                    ? item?.addon_item[0]?.variation[0]?.size
                                    : null}
                                </h4>
                              );
                            })}
                          </>
                        ) : (
                          <h4 className="variation-type">$0</h4>
                        )}
                      </td>

                      <td>
                        <h4 className="price">${item?.price}</h4>
                        {/* {
                             item?.addon_item[0] ? 
                             (
                              <h4 className="price">
                                {parseInt(item?.price) + parseInt(item?.addon_item[0]?.single_addon?.unit_price)}
                                </h4>
                             ) : (
                              <h4 className="price">{item?.price}</h4>
                             )
                          } */}
                      </td>
                      <td className="quantityTh">
                        <div className="quantity">
                          <button
                            className="minus"
                            onClick={() => Decrement(item?.productitem?.id)}
                            disabled={
                              item?.quantity <= 1 || item?.type == "bulk"
                            }
                          >
                            -
                          </button>
                          <span> {item?.quantity}</span>
                          <button
                            className="plus"
                            onClick={() => Increment(item?.productitem?.id)}
                            disabled={
                              item?.type == "bulk" ||
                              filterVariationQty(item) == item?.quantity
                            }
                          >
                            +
                          </button>
                        </div>
                      </td>
                      <td>
                        <h4 className="subtotal">
                          {item?.type == "bulk" ? (
                            <>
                              $
                              {item?.addon_item
                                ? parseInt(item?.price) *
                                  parseInt(item?.quantity)
                                : item?.price}
                            </>
                          ) : (
                            <>
                              $
                              {item?.addon_item
                                ? item?.quantity * item?.price +
                                  SpecificItemHandler(item?.addon_item)
                                : // parseInt(
                                  //   item?.addon_variationPrice
                                  // ) * parseInt(
                                  //   item?.addonQuantity
                                  // )
                                  item?.quantity * item?.price}
                            </>
                          )}
                        </h4>
                      </td>
                      <td>
                        <div
                          className="delete"
                          onClick={(e) => RemoveCartItem(item?.productitem?.id)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="26"
                            height="26"
                            fill="#EF5D61"
                            className="bi bi-x"
                            viewBox="0 0 16 16"
                          >
                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                          </svg>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {cartDataRedux.length > 0 ? null : <NoRecord />}
            <div className="bottm-bar">
              <div className="row align-items-center">
                {/* <div className="col-lg-6">
                  <div className="coupon">
                    <label>Gift Certificate code</label>
                    <div className="form-group">
                      <input
                        type="text"
                        placeholder="Enter your coupon code if you have one."
                        className="form-control"
                      />
                      <button className="btn" type="submit">
                        Apply
                      </button>
                    </div>
                  </div>
                </div> */}
                <div className="col-lg-6">
                  <div className="button-group">
                    <Link to="/all-plants" className="btn mr-3">
                      Continue Shopping
                    </Link>
                    <a className="btn" onClick={(e) => dispatch(ClearCart())}>
                      Clear Cart
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {cartDataRedux?.length > 0 ? (
              <div className="amount-calculation">
                <div className="row align-items-center">
                  <div className="col-lg-6">
                    <div className="all-amount">
                      <p className="subtotal">
                        <span className="property">Donation</span>
                        <span className="value">
                          ${totalDonation ? totalDonation : 0}
                        </span>
                      </p>
                      <p className="subtotal">
                        <span className="property">Subtotal</span>
                        <span className="value">${subTotal}</span>
                      </p>
                      <p className="shipping">
                        <span className="property">
                          Estimated Shipping Cost
                        </span>
                        <span className="value">$ Free Shipping</span>
                      </p>
                      <p className="total">
                        <span className="property">TOTAL COST </span>
                        <span className="value">${subTotal}</span>
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="button-group">
                      {isLogin && cartDataRedux?.length > 0 ? (
                        <Link
                          to={`/checkout`}
                          state={{
                            data: {
                              CartData: cartDataRedux,
                              total: subTotal,
                              // couponData: couponData,
                            },
                          }}
                          className="btn"
                        >
                          Continue To checkout
                        </Link>
                      ) : (
                        <button
                          className="btn"
                          onClick={(e) => LoginHandler(e)}
                        >
                          Continue To checkout
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </section>
      {/* <!-- Cart Sec End Here --> */}

      {frequentData?.length > 0 ? (
        <section className="cart-frequentProduct">
          <div className="container">
            <h4 className="mb-4">Don't Forget These:</h4>
            <div className="row">
              {frequentData?.map((item, index) => {
                return (
                  <>
                    {index < 4 ? (
                      <div className="col-lg-3 col-md-6 mb-3" key={index}>
                        <div
                          className={`${
                            index == 3 ? "product-box" : "product-box "
                          }`}
                          // pro_boxs
                          id="multi_select"
                        >
                          <Link to={`/product-detail/${item?.id}`}>
                            <div
                              className={"pro-img"}
                              // onClick={(e) => AddFrequentHandler(e, item)}
                            >
                              <figure>
                                <img
                                  src={
                                    item?.thumbnail && item?.thumbnail != null
                                      ? `${ImageUrlRedux?.product_thumbnail_url}/${item?.thumbnail}`
                                      : `${no_image_product_details}`
                                  }
                                  alt=""
                                  className="img-fluid"
                                />
                              </figure>
                            </div>
                            <div className="pro-detail">
                              <div className="name">
                                <h4>{item?.name}</h4>
                              </div>
                              <div className="price">
                                <h4>${item?.unit_price}</h4>
                              </div>
                            </div>
                          </Link>
                        </div>

                        {/* <select
                    id="size"
                    className="form-control"
                    key={index}
                    onChange={(e) =>
                      FrequentVariations(e.target.value, item)
                    }
                  >
                    <option selected disabled>
                      Select Size
                    </option>
                    {item?.variation?.map((item2, index) => {
                      return (
                        <option value={item2?.size} key={index}>
                          {item2?.size}
                        </option>
                      );
                    })}
                  </select> */}
                      </div>
                    ) : null}
                  </>
                );
              })}
            </div>
          </div>
        </section>
      ) : null}

      <Footer />
    </>
  );
};

export default Cart;
