import React, { useEffect, useState } from "react";
import "../../assets/style/profile.css";
import Footer from "../Footer";
import Header from "../Header";
import {
  proafter,
  probefore,
  approved,
  productdetail3,
  bird2,
} from "../../constant/index";
import { Link } from "react-router-dom";
import { GetAllOrder, UpdateProfile } from "../../network/Network";
import { EditProfileData } from "../../redux/actions/AuthActions";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import SideBar from "../SideBar/SideBar";
import ReactPaginate from "react-paginate";
import NoRecord from "../NoRecord/NoRecord";
import Skeleton from "react-loading-skeleton";
import ViewOrderModal from "../Modal/ViewOrderModal";
import moment from "moment";

const MyOrders = () => {
  const dispatch = useDispatch();
  const Token = useSelector((state) => state.AuthReducer.token);
  const userData = useSelector((state) => state.AuthReducer.users);

  const [myOrderData, setMyOrderData] = useState([]);
  const [spinLoad, setSpinLoad] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState();

  const [isOpenOrderModal, setIsOpenOrderModal] = useState(false);
  const [selectedData, setSelectedData] = useState();

  const handlePageClick = async (data) => {
    setCurrentPage(data?.selected + 1);
  };

  useEffect(() => {
    setSpinLoad(true);
    GetAllOrder(currentPage, Token)
      .then((res) => {
        // console.log("my order", res);
        setMyOrderData(res?.data?.data);
        const total = res?.data?.total;
        const limit = res?.data?.per_page;
        setPageCount(Math.ceil(total / limit));
        setSpinLoad(false);
      })
      .catch((err) => {
        // console.log(err);
        setSpinLoad(false);
      });
  }, [currentPage]);

  function capitalizeFirstWord(str) {
    if (!str || typeof str !== 'string') {
      return str; // Return the input unchanged if it's not a string or empty
    }
  
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  return (
    <>
      <Header />
      {/* <!-- Profile Sec Start Here --> */}
      <section className="profile-sec">
        <div className="before">
          <figure>
            <img src={probefore} className="img-fluid" />
          </figure>
        </div>
        <div className="container">
          <div className="heading">
            <h2>My Profile</h2>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <SideBar />
            </div>
            <div className="col-lg-8">
              <div className="tab-content" id="v-pills-tabContent">
                {/* <!-- Fourth Tab Content --> */}
                <div
                  className={"tab-pane fade show active"}
                  id="v-pills-Orders"
                  role="tabpanel"
                  aria-labelledby="v-pills-Orders-tab"
                >
                  <div className="tab-box sixth-box">
                    <div className="donations box">
                      <div className="heading">
                        <h3>My Orders</h3>
                      </div>
                      {spinLoad ? (
                        <>
                          {[0, 1, 2, 3, 4, 5, 7, 8, 9]?.map((item, index) => {
                            return (
                              <div className="col-md-12 mt-3" key={index}>
                                <Skeleton count={5} />
                              </div>
                            );
                          })}
                        </>
                      ) : myOrderData?.length > 0 ? (
                        <>
                          <div className="donation-table">
                            <div className="table-reponsive">
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th>OrderNo</th>
                                    <th>Order Date</th>
                                    <th>Status</th>
                                    <th>Payment Status</th>
                                    <th>Total</th>
                                    <th>Details</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {myOrderData?.map((item, index) => {
                                    return (
                                      <>
                                        <tr key={index}>
                                          <td>#{item?.id}</td>
                                          <td>
                                            {moment(item?.created_at).format(
                                              "MMMM Do YYYY, h:mm a"
                                            )}{" "}
                                          </td>
                                          <td>{capitalizeFirstWord(item?.order_status)}</td>
                                          <td>
                                            <div className="status">
                                              <span>
                                                {item?.payment_status?.toUpperCase()}
                                              </span>
                                              <figure>
                                                <img
                                                  src={approved}
                                                  className="img-fluid"
                                                />
                                              </figure>
                                            </div>
                                          </td>
                                          <td>${item?.discount_amount ? item?.order_amount - item?.discount_amount : item?.order_amount}</td>
                                          <td>
                                            <button
                                              type="button"
                                              className="btn btn-primary"
                                              // data-bs-toggle="modal"
                                              // data-bs-target="#orderDetail"
                                              onClick={(e) => {
                                                setIsOpenOrderModal(true);
                                                setSelectedData(item);
                                              }}
                                            >
                                              View Detail
                                            </button>
                                          </td>
                                        </tr>
                                      </>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </>
                      ) : (
                        <NoRecord />
                      )}

                      <div className="paginatio-count">
                        <div className="pagination-container mt-5">
                          <ReactPaginate
                            previousLabel="<"
                            nextLabel=">"
                            breakLabel={"..."}
                            pageCount={pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={3}
                            onPageChange={handlePageClick}
                            containerClassName={
                              "pagination justify-content-center"
                            }
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            activeClassName={"active"}
                          />
                        </div>
                        {myOrderData?.length > 0 ? (
                          <p className="total-pages">
                            {myOrderData?.length} PRODUCTS 1 -{" "}
                            {myOrderData?.length}
                          </p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- Fourth Tab Content --> */}
              </div>
            </div>
          </div>
        </div>
        <div className="after">
          <figure>
            <img src={proafter} className="img-fluid" />
          </figure>
        </div>
      </section>
      {/* <!-- Profile Sec End Here --> */}

      {/* <!-- My Order Popup --> */}

      {/* <!-- My Order Popup --> */}

      {/* <!-- Donation Detail Popup --> */}
      <div
        className="modal fade"
        id="donationDetail"
        tabindex="-1"
        aria-labelledby="donationDetailLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="heading">
                <h2>Donation Details</h2>
              </div>
              <div className="all-orders">
                <div className="order-detail">
                  <div className="img-box">
                    <figure>
                      <img src={bird2} alt="" className="img-fluid" />
                    </figure>
                  </div>
                  <div className="details">
                    <h5 className="name">Kigi bird</h5>
                    <h5 className="price">
                      <span className="pro-name">Price: $590.00</span>
                      <span className="order-no">order no: #1084598423154</span>
                    </h5>
                    <h5 className="status">
                      <span>Paid</span>
                    </h5>
                  </div>
                </div>
                <div className="order-detail">
                  <div className="img-box">
                    <figure>
                      <img src={bird2} alt="" className="img-fluid" />
                    </figure>
                  </div>
                  <div className="details">
                    <h5 className="name">Kigi bird</h5>
                    <h5 className="price">
                      <span className="pro-name">Price: $590.00</span>
                      <span className="order-no">order no: #1084598423154</span>
                    </h5>
                    <h5 className="status">
                      <span>Paid</span>
                    </h5>
                  </div>
                </div>
                <div className="order-detail">
                  <div className="img-box">
                    <figure>
                      <img src={bird2} alt="" className="img-fluid" />
                    </figure>
                  </div>
                  <div className="details">
                    <h5 className="name">Kigi bird</h5>
                    <h5 className="price">
                      <span className="pro-name">Price: $590.00</span>
                      <span className="order-no">order no: #1084598423154</span>
                    </h5>
                    <h5 className="status">
                      <span>Paid</span>
                    </h5>
                  </div>
                </div>
                <div className="order-detail">
                  <div className="img-box">
                    <figure>
                      <img src={bird2} alt="" className="img-fluid" />
                    </figure>
                  </div>
                  <div className="details">
                    <h5 className="name">Kigi bird</h5>
                    <h5 className="price">
                      <span className="pro-name">Price: $590.00</span>
                      <span className="order-no">order no: #1084598423154</span>
                    </h5>
                    <h5 className="status">
                      <span>Paid</span>
                    </h5>
                  </div>
                </div>
              </div>
              <ul className="order-summary">
                <li>
                  <span>Sub Total :</span>
                  <span>$ 147.00</span>
                </li>
                <li>
                  <span>Total Amount :</span>
                  <span>$ 150.00</span>
                </li>
              </ul>
              <div className="button-group">
                <a href="kigi-birds.php" className="btn">
                  Continue
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Donation Detail Popup --> */}
      <Footer />

      <ViewOrderModal
        setIsOpenOrderModal={setIsOpenOrderModal}
        isOpenOrderModal={isOpenOrderModal}
        selectedData={selectedData}
      />
    </>
  );
};

export default MyOrders;
