import React, { useEffect, useState } from "react";
import "../../assets/style/profile.css";
import Footer from "../Footer";
import Header from "../Header";
import {
  cardimage1,
  cardimage2,
  proafter,
  probefore,
  pointer,
  profile,
  wishlistpro,
  approved,
  productdetail3,
  bird2,
} from "../../constant/index";
import { Link } from "react-router-dom";
import { PostChangePassword, UpdateProfile } from "../../network/Network";
import { EditProfileData } from "../../redux/actions/AuthActions";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import SideBar from "../SideBar/SideBar";

const Profile = () => {
  const dispatch = useDispatch()
	const Token = useSelector((state) => state.AuthReducer.token);
	const userData = useSelector((state) => state.AuthReducer.users);

  const [newFile, setNewFile] = useState("");
	const [fileupload, setFileupload] = useState("");
	const [uploadLoading, setUploadLoading] = useState(false);
	const [editState, setEditState] = useState(false);
	const [passLoading, setPassLoading] = useState(false);

	const [firstName, setFirstName] = useState(userData?.f_name);
	const [lastName, setLastName] = useState(userData?.l_name);
	const [phone, setPhone] = useState(userData?.phone);
	const [editLoading, setEditLoading] = useState(false);

	const [pass, setPass] = useState("*******");
	const [currentPassword, setCurrentPassword] = useState("");
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");

	const [passwordChange, setPasswordChange] = useState(false);
	const [loading, setLoading] = useState(false);

  const [active, setActive] = useState(1);
  const [tabs, setTabs] = useState(1);

  const handleImageUpload = (e) => {
		e.preventDefault();
		const reader = new FileReader();
		const file = e.target.files[0];
		reader.onloadend = () => {
			setNewFile(file);
			setFileupload(reader.result);
		};
		reader.readAsDataURL(file);
		setUploadLoading(true);
	};


  const EditProfileHandler = (e) => {
		e.preventDefault();
		setEditLoading(true);
		if (
		  !firstName ||
		  !lastName ||
		  !phone 
		) {
		  setLoading(false);
		  toast.error("Please Enter All Field");
		  return;
		}

		let data = new FormData();
		data.append("f_name", firstName);
		data.append("l_name", lastName);
		data.append("phone", phone);
		data.append("image", newFile);
		// console.log(data);
		UpdateProfile(data, Token)
		  .then((res) => {
			setEditLoading(false);
			setUploadLoading(false);
			toast.success(res?.data?.message);
			// console.log("edit prf",res);
			dispatch(EditProfileData(res?.data?.data[0]));
      setTabs(1)
			setEditState(!editState)
		  })
		  .catch((err) => {
			// console.log(err);
			setEditLoading(false);
			setUploadLoading(false);
		  });
	  };

    const changePasswordHandler = (e) => {
      e.preventDefault();
      setPassLoading(true);
      if (
        !currentPassword ||
        !password ||
        !confirmPassword 
      ) {
        setPassLoading(false)
        toast.error("Please Enter All Field");
        return;
      }
      if (password !== confirmPassword ) {
        setPassLoading(false)
        toast.error("New Password and confirm Password are not same ");
        return;
      }
      let data = new FormData();
      data.append("current_password", currentPassword);
      data.append("password", password);
      data.append("confirm_password", confirmPassword);
      // console.log(data);
      PostChangePassword(data, Token)
        .then((res) => {
        setPassLoading(false);
        // console.log(res)
        toast.success(res?.data?.message);
        setTabs(1)
        setCurrentPassword("");
        setPassword("");
        setConfirmPassword("");
        })
        .catch((err) => {
        // console.log(err);
        toast.error( err?.response?.data?.error)
        setPassLoading(false);
        });
      };

   

  return (
    <>
      <Header />
      {/* <!-- Profile Sec Start Here --> */}
      <section className="profile-sec">
        <div className="before">
          <figure>
            <img src={probefore} className="img-fluid" />
          </figure>
        </div>
        <div className="container">
          <div className="heading">
            <h2>My Profile</h2>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <SideBar 
                uploadLoading={uploadLoading}
                fileupload={fileupload}
                handleImageUpload={handleImageUpload}
                tabs={ tabs}
              />
        
            </div>
            <div className="col-lg-8">
              <div className="tab-content" id="v-pills-tabContent">
                {/* <!-- First Tab Content --> */}
                <div
                  className={`tab-pane fade show active`}
                  id="v-pills-Account"
                  role="tabpanel"
                  aria-labelledby="v-pills-Account-tab"
                >
                  <div
                    className={tabs === 1 ? "tab-box first-box" : "tab-box first-box hide"}>
                    <div className="persnol-information box">
                      <div className="heading">
                        <h3>Personal Information</h3>
                        <button className="editInformation edit" onClick={() => setTabs(2)}>
                          <i className="fa fa-pencil" aria-hidden="true"></i>
                          <span>Edit</span>
                        </button>
                      </div>
                      <div className="row align-items-center mr-20">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>First Name</label>
                            <h3 className="userDetail">{userData?.f_name}</h3>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Last Name</label>
                            <h3 className="userDetail">{userData?.l_name}</h3>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Phone</label>
                            <h3 className="userDetail">+{userData?.phone}</h3>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Email</label>
                            <h3 className="userDetail">{userData?.email}</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="persnol-information box">
                      <div className="heading">
                        <h3>Password</h3>
                        <button className="editPassword edit" onClick={() => setTabs(3)}>
                          <i className="fa fa-pencil" aria-hidden="true"></i>
                          <span>Edit</span>
                        </button>
                      </div>
                      {/* <div className="row align-items-center mr-20">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Password</label>
                            <input
                              type="password"
                              value="123456789"
                              readonly
                              className="form-control"
                              
                            />
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                  <div
                    className={tabs === 2 ? "tab-box first-box" : "tab-box first-box hide"}>
                    <div className="change-information box">
                      <div className="heading">
                        <h3>Personal Infmation</h3>
                      </div>
                      <div className="row align-items-center mr-20">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>First Name</label>
                            <input type="text" className="form-control" 
                              value={firstName}
                            onChange={(e) =>
                              setFirstName(e.target.value)
                            }
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Last Name</label>
                            <input type="text" className="form-control" 
                              value={lastName}
                              onChange={(e) =>
                                setLastName(e.target.value)
                              }
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Phone</label>
                            <input type="number" className="form-control" 
                              value={phone}
                              onChange={(e) => setPhone(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          {/* <div className="form-group">
                            <label>Email</label>
                            <input type="email" className="form-control" 
                            
                            />
                          </div> */}
                        </div>
                        <div className="col-md-12">
                          <div className="button-group">
                            <button className="btn saveInformation " 
                              onClick={(e) => EditProfileHandler(e)}
															disabled={editLoading}
                            >
                              {editLoading ? "Loading..." : "SAVE"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={tabs === 3 ? "tab-box first-box" : "tab-box first-box hide"}>
                    <div className="change-pass box">
                      <div className="heading">
                        <h3>Change Password</h3>
                      </div>
                      <div className="row align-items-center mr-20">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Old Password</label>
                            <input
                              type="password"
                              className="form-control"
                              value={currentPassword}
															onChange={(e) =>
																setCurrentPassword(e.target.value)
															}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>New Password</label>
                            <input
                              type="password"
                              className="form-control"
                              value={password}
															onChange={(e) => setPassword(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Confirm Password</label>
                            <input
                              type="password"
                              className="form-control"
                              value={confirmPassword}
															onChange={(e) =>
																setConfirmPassword(e.target.value)
															}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group mt-4">
                            <div className="button-group">
                              <button className="btn savePassword" 
                                onClick={(e) => changePasswordHandler(e)}
													      disabled={passLoading}>
                               {passLoading ? "Loading..." : "SAVE PASSWORD "}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- First Tab Content --> */}
              </div>
            </div>
          </div>
        </div>
        <div className="after">
          <figure>
            <img src={proafter} className="img-fluid" />
          </figure>
        </div>
      </section>
      {/* <!-- Profile Sec End Here --> */}

      {/* <!-- My Order Popup --> */}
      <div
        className="modal fade"
        id="orderDetail"
        tabindex="-1"
        aria-labelledby="orderDetailLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="heading">
                <h2>Order Details</h2>
              </div>
              <div className="all-orders">
                <div className="order-detail">
                  <div className="img-box">
                    <figure>
                      <img
                        src={productdetail3}
                        alt=""
                        className="img-fluid"
                      />
                    </figure>
                  </div>
                  <div className="details">
                    <h5 className="name">Nutient</h5>
                    <h5 className="price">
                      <span className="pro-name">Price: $590.00</span>
                      <span className="order-no">order no: #1084598423154</span>
                    </h5>
                    <h5 className="status">
                      <span>
                        <label>Quantity</label>(2)
                      </span>
                      <span>Paid</span>
                    </h5>
                  </div>
                </div>
                <div className="order-detail">
                  <div className="img-box">
                    <figure>
                      <img
                        src={productdetail3}
                        alt=""
                        className="img-fluid"
                      />
                    </figure>
                  </div>
                  <div className="details">
                    <h5 className="name">Nutient</h5>
                    <h5 className="price">
                      <span className="pro-name">Price: $590.00</span>
                      <span className="order-no">order no: #1084598423154</span>
                    </h5>
                    <h5 className="status">
                      <span>
                        <label>Quantity</label>(2)
                      </span>
                      <span>Paid</span>
                    </h5>
                  </div>
                </div>
                <div className="order-detail">
                  <div className="img-box">
                    <figure>
                      <img
                        src={productdetail3}
                        alt=""
                        className="img-fluid"
                      />
                    </figure>
                  </div>
                  <div className="details">
                    <h5 className="name">Nutient</h5>
                    <h5 className="price">
                      <span className="pro-name">Price: $590.00</span>
                      <span className="order-no">order no: #1084598423154</span>
                    </h5>
                    <h5 className="status">
                      <span>
                        <label>Quantity</label>(2)
                      </span>
                      <span>Paid</span>
                    </h5>
                  </div>
                </div>
                <div className="order-detail">
                  <div className="img-box">
                    <figure>
                      <img
                        src={productdetail3}
                        alt=""
                        className="img-fluid"
                      />
                    </figure>
                  </div>
                  <div className="details">
                    <h5 className="name">Nutient</h5>
                    <h5 className="price">
                      <span className="pro-name">Price: $590.00</span>
                      <span className="order-no">order no: #1084598423154</span>
                    </h5>
                    <h5 className="status">
                      <span>
                        <label>Quantity</label>(2)
                      </span>
                      <span>Paid</span>
                    </h5>
                  </div>
                </div>
              </div>
              <ul className="order-summary">
                <li>
                  <span>Sub Total :</span>
                  <span>$ 147.00</span>
                </li>
                <li>
                  <span>Disscount Code Applied :</span>
                  <span>-$ 14.00</span>
                </li>
                <li>
                  <span>Shipping Cost :</span>
                  <span>$ 69.00</span>
                </li>
                <li>
                  <span>Total Amount :</span>
                  <span>$ 150.00</span>
                </li>
              </ul>
              <div className="button-group">
                <Link to="/shop" className="btn">
                  Continue Shopping
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- My Order Popup --> */}

      {/* <!-- Donation Detail Popup --> */}
      <div
        className="modal fade"
        id="donationDetail"
        tabindex="-1"
        aria-labelledby="donationDetailLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="heading">
                <h2>Donation Details</h2>
              </div>
              <div className="all-orders">
                <div className="order-detail">
                  <div className="img-box">
                    <figure><img src={bird2} alt="" className="img-fluid" /></figure>
                  </div>
                  <div className="details">
                    <h5 className="name">Kigi bird</h5>
                    <h5 className="price">
                      <span className="pro-name">Price: $590.00</span>
                      <span className="order-no">order no: #1084598423154</span>
                    </h5>
                    <h5 className="status">
                      <span>Paid</span></h5>
                  </div>
                </div>
                <div className="order-detail">
                  <div className="img-box">
                    <figure><img src={bird2} alt="" className="img-fluid" /></figure>
                  </div>
                  <div className="details">
                    <h5 className="name">Kigi bird</h5>
                    <h5 className="price">
                      <span className="pro-name">Price: $590.00</span>
                      <span className="order-no">order no: #1084598423154</span>
                    </h5>
                    <h5 className="status"><span>Paid</span></h5>
                  </div>
                </div>
                <div className="order-detail">
                  <div className="img-box">
                    <figure><img src={bird2} alt="" className="img-fluid" /></figure>
                  </div>
                  <div className="details">
                    <h5 className="name">Kigi bird</h5>
                    <h5 className="price"><span className="pro-name">Price: $590.00</span><span className="order-no">order no: #1084598423154</span></h5>
                    <h5 className="status">

                      <span>Paid</span></h5>
                  </div>
                </div>
                <div className="order-detail">
                  <div className="img-box">
                    <figure><img src={bird2} alt="" className="img-fluid" /></figure>
                  </div>
                  <div className="details">
                    <h5 className="name">Kigi bird</h5>
                    <h5 className="price"><span className="pro-name">Price: $590.00</span><span className="order-no">order no: #1084598423154</span></h5>
                    <h5 className="status"><span>Paid</span></h5>
                  </div>
                </div>
              </div>
              <ul className="order-summary">
                <li>
                  <span>Sub Total :</span>
                  <span>$ 147.00</span>
                </li>
                <li>
                  <span>Total Amount :</span>
                  <span>$ 150.00</span>
                </li>
              </ul>
              <div className="button-group">
                <a href="kigi-birds.php" className="btn">
                  Continue
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Donation Detail Popup --> */}
      <Footer />
    </>
  );
};

export default Profile;
